<template>
  <div class="newsdesc">
    <normaltop
      :url="require('../assets/images/news/bg.png')"
      title="行业资讯"
      en="Industry information"
    ></normaltop>
    <div class="news-cont">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/news' }"
          >新闻资讯</el-breadcrumb-item
        >
        <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="news-main">
        <h1 class="news-title">{{ news.name }}</h1>
        <div class="date-box">
          <p>来源：{{ news.brief }}</p>
          <p>{{ news.push_date }}</p>
        </div>
        <!-- <p class="news-desc" v-html="news.content">{{ news.content }}</p> -->
        <div class="ql-container ql-snow">
          <div
            class="ql-editor news-desc"
            v-html="news.content"
            ref="imgdiv"
          ></div>
        </div>
      </div>
      <!-- <div class="news-bottom">
        <span>下一篇：关于2020年第三季度启用薪税师新课程体系的通知</span>
        <span>文章分类： {{news.type==1?'新闻资讯':'站内公告'}}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Normaltop from '../components/normaltop.vue'
import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  name: 'NewsDesc',
  components: { Normaltop },
  data() {
    return {
      news: {},
      articleId: '',
      articleType: '',
    }
  },
  created() {
    this.init()
    // this.articleType = this.$route.params.type
  },
  mounted() {},
  methods: {
    init() {
      let url = ''
      if (this.$route.query.type == 1) {
        url = 'articleInfoNotices/'
      } else {
        url = 'articleInfoNEws/'
      }
      let _this = this
      _this.$http
        .get(process.env.VUE_APP_URL + url + _this.$route.query.id)
        .then(data => {
          if (data.data.code == 200) {
            _this.news = data.data.data
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
  },
}
</script>
<style lang="less" scoped>
.el-breadcrumb {
  height: 40px;
  line-height: 40px;
}
.newsdesc {
  font-weight: normal;
  font-size: 16px;
  color: #333333;
  img {
    width: 100%;
  }
  .news-cont {
    width: 68%;
    min-width: 1100px;
    margin: 0 auto;
    .link-tit {
      padding: 50px 0 25px;
      font-size: 18px;
    }
    .news-main {
      border: 1px solid #d8d8d8;
      padding: 50px 70px;
      margin-bottom: 35px;
      .news-title {
        font-size: 36px;
        color: #6f0c08;
        text-align: center;
      }
      .date-box {
        padding: 30px;
        display: flex;
        text-align: right;
        justify-content: space-around;
      }
    }
    .news-bottom {
      margin: 0 100px;
      display: flex;
      justify-content: space-between;
      padding: 20px 0 50px;
      border-top: 1px solid #d8d8d8;
    }
  }
  .ql-editor {
    font-size: 18px;
  }
}
</style>
